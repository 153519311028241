.react-calendar {
    width: 100%;
    background: black;
    border-radius: 8px;
    font-size: 15px;
  }
  .colormode-light .react-calendar {
    background: white;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
  }
  .react-calendar__navigation button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    min-width: 44px;
    background: none;
    border-radius: 8px;
  }
  .react-calendar__navigation button:disabled {
    background-color: none;
    color: #111;
    cursor: default;
  }
  .colormode-light .react-calendar__navigation button:disabled {
    color: #ccc;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:active {
    background-color: #333;
  }
  .colormode-light .react-calendar__navigation button:enabled:hover,
  .colormode-light .react-calendar__navigation button:enabled:active {
    background-color: rgba(0,0,0,0.1);
  }
  .react-calendar__navigation__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px !important;
    font-weight: normal !important;
  }
  .react-calendar__month-view__weekdays {
    display: none !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #888;
  }
  .react-calendar__month-view__days__day--neighboringMonth:hover,
  .react-calendar__month-view__days__day--neighboringMonth:active {
    color: #fff;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 20px 4px;
    font-size: 14px;
    text-transform: uppercase;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6px;
    background: none;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    border-radius: 8px;
  }
  .react-calendar__tile:disabled {
    background-color: none;
    color: #333;
    cursor: default;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #333;
  }
  .colormode-light .react-calendar__tile:enabled:hover,
  .colormode-light .react-calendar__tile:enabled:focus {
    background-color: rgba(0,0,0,0.1);
  }
  .react-calendar__tile--now {
    background: #b96c0f;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #f7931a;
  }
  .react-calendar__tile--hasActive {
    background: #f7931a;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #f7931a;
  }
  .react-calendar__tile--active {
    background: #f7931a;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #f7931a;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #333;
  }
  
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	background: black;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@keyframes moveFlame {
	0%, 100% {
	  transform: scaleY(1) translateX(-50%) rotate(-3deg);
	}
	50% {
	  transform: scaleY(1.1) translateX(-50%) rotate(3deg);
	}
}
@keyframes blinkGlow {
	0%, 100% { opacity: .7; }
	50% { opacity: .5; }
}

@keyframes blurIn {
	0% {
		opacity: 0;
		filter: 'blur(4px)';
	}
	100% {
		opacity: 1;
		filter: 'blur(0px)';
	}
}
